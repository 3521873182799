import gsap from 'gsap';

export function enterDeepDive(el: Element, done: () => void) {
  const innerEl = el.querySelector('.horizontal-scroll-container .inner');

  const tl = gsap.timeline({
    defaults: {
      duration: 0.75,
      ease: 'power4.out',
    },
    onComplete: done,
  });

  if (window.innerWidth < 800) {
    tl.fromTo(
      el,
      {
        opacity: 0,
      },
      {
        opacity: 1,
      },
    );
  } else {
    tl.fromTo(
      innerEl,
      {
        clipPath: `inset(10% 0% 10% ${window.innerWidth}px)`,
      },
      {
        duration: 1,
        clipPath: `inset(5% 0% 5% 30px)`,
      },
    ).to(
      innerEl,
      {
        clipPath: 'inset(0% 0% 0% 0px)',
      },
      '<52%',
    );
  }
}
