import gsap from 'gsap';

export function enterDefault(el: Element, done: () => void) {
  gsap.set(el, {
    zIndex: -1,
    position: 'relative',
  });

  gsap.set(el, {
    zIndex: 0,
    delay: 1,
    onComplete: done,
  });
}
