<template>
  <div class="error-page">
    <h1 class="text-3xl">Page does not exist</h1>
    <p class="button" @click="handleError()">Back zu home</p>
  </div>
</template>

<script setup>
function handleError() {
  clearError({ redirect: '/' });
}
</script>

<style scoped lang="scss">
.error-page {
  color: #ffffff;
  height: 100lvh;
  background-color: #1c1c1c;
  padding: var(--base-component-padding);

  > .button {
    cursor: pointer;
  }
}
</style>
