import InlineLink from '~/components/richtext/InlineLink.vue';
import type {
  DefaultPageLinkFragment,
  DeepDiveLinkFragment,
  JobDeepDiveLinkFragment,
  ProjectDeepDiveLinkFragment,
} from '#gql';

export default function renderInlineRecord({
  record,
}: {
  record:
    | (DefaultPageLinkFragment & { __typename: 'DefaultPageRecord' })
    | (DeepDiveLinkFragment & { __typename: 'DeepDiveRecord' })
    | (JobDeepDiveLinkFragment & { __typename: 'JobDeepDivePageRecord' })
    | (ProjectDeepDiveLinkFragment & {
        __typename: 'ProjectDeepDivePageRecord';
      });
}) {
  return h(InlineLink, { record });
}
