<template>
  <div class="app">
    <a :href="`${config.public.appBaseUrl}#control-panel`" class="skip-link"
      >Zur Navigation</a
    >
    <a :href="`${config.public.appBaseUrl}#contact`" class="skip-link"
      >Zum Kontakt</a
    >
    <a :href="`${config.public.appBaseUrl}#projects`" class="skip-link"
      >Zu den Projekten</a
    >
    <ControlPanel />
    <main class="page-main">
      <NuxtPage :key="locale" />
    </main>
  </div>
</template>

<script setup lang="ts">
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { SplitText } from 'gsap/SplitText';
import { Observer } from 'gsap/Observer';
import { CustomEase } from 'gsap/CustomEase';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import ControlPanel from '~/components/control-panel/ControlPanel.vue';
import { ControlPanelInjectionKey } from '~/utils/useControlPanel';

gsap.registerPlugin(CustomEase);
gsap.registerPlugin(SplitText);
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(Observer);
gsap.registerPlugin(ScrollToPlugin);

const { locale, localeProperties } = useI18n();

const nuxtApp = useNuxtApp();
const config = useRuntimeConfig();

useHead({
  htmlAttrs: {
    dir: localeProperties.value.dir,
    lang: localeProperties.value.iso,
  },
});

const { data: dataSiteInfo } = await useAsyncGql({
  operation: 'GetSiteInfo',
});

const SiteInfo = dataSiteInfo.value.siteInfo;

nuxtApp.provide('SiteInfo', SiteInfo);

const { data: dataTranslationPanel } = await useAsyncGql({
  operation: 'GetTranslationPanel',
});

const TranslationPanel = dataTranslationPanel.value?.translationPanel;

nuxtApp.provide('TranslationPanel', TranslationPanel);

const controlPanelInjection = useControlPanel();
provide(ControlPanelInjectionKey, controlPanelInjection);
</script>

<style lang="scss">
body {
  &:not(.-ready) {
    max-height: 100vh;
    overflow-y: hidden;
  }
}
</style>

<style scoped lang="scss">
.app {
  position: relative;
}
</style>
