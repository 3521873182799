export function useAnimateOnReady(callback: () => void) {
  if ('requestAnimationFrame' in window) {
    const animate = () => {
      requestAnimationFrame(() => {
        if (
          document.readyState === 'complete' &&
          document.body.classList.contains('-page-ready')
        ) {
          callback();
        } else {
          animate();
        }
      });
    };
    animate();
  } else {
    // Fallback for older browsers that don't support requestAnimationFrame
    // @ts-ignore
    window.addEventListener('load', callback);
  }
}
