import gsap from 'gsap';

export function leaveDeepDive(el: Element, done: () => void) {
  const innerEl = el.querySelector('.horizontal-scroll-container .inner');
  const innerElX = gsap.getProperty(innerEl, 'x') as number;

  const canvas = el.querySelector('canvas');

  const tl = gsap.timeline({
    defaults: {
      duration: 1,
      ease: 'power4.out',
    },
    onComplete: () => {
      done();
    },
  });

  if (window.innerWidth < 800) {
    tl.to(el, {
      opacity: 0,
    });
  } else {
    gsap.set(el, {
      position: 'relative',
      zIndex: 100,
    });

    if (canvas) {
      gsap.set(canvas, {
        visibility: 'hidden',
        opacity: 0,
      });
    }

    tl.fromTo(
      innerEl,
      { clipPath: () => `inset(0% 0% 0% ${innerElX * -1}px)` },
      {
        clipPath: () =>
          `inset(10% 0% 10% ${innerElX * -1 + window.innerWidth}px)`,
      },
    );
  }
}
