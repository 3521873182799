<template>
  <NuxtLink v-if="link" :to="link">{{ record.title }}</NuxtLink>
</template>

<script setup lang="ts">
import type {
  DefaultPageLinkFragment,
  DeepDiveLinkFragment,
  JobDeepDiveLinkFragment,
  ProjectDeepDiveLinkFragment,
} from '#gql';

const { locale } = useI18n();

const props = defineProps<{
  record:
    | (DefaultPageLinkFragment & { __typename: 'DefaultPageRecord' })
    | (DeepDiveLinkFragment & { __typename: 'DeepDiveRecord' })
    | (JobDeepDiveLinkFragment & { __typename: 'JobDeepDiveRecord' })
    | (ProjectDeepDiveLinkFragment & {
        __typename: 'ProjectDeepDiveRecord';
      });
}>();

const link = computed(function () {
  let linkBase = '/';

  if (locale.value !== 'de') {
    linkBase += `${locale.value}/`;
  }

  if (props.record.__typename === 'JobDeepDiveRecord') {
    linkBase += 'jobs/';
  }

  if (props.record.__typename === 'ProjectDeepDiveRecord') {
    linkBase += 'projects/';
  }

  const translatedSlug =
    props.record?._allTranslatedSlugLocales?.find(
      (it) => it.locale === locale.value,
    )?.value ?? null;

  return `${linkBase}${translatedSlug}`;
});
</script>

<style scoped lang="scss"></style>
