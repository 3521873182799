import { useGql } from '#imports'
import { getSdk as defaultGqlSdk } from '#gql/default'
export const GqlSdks = {
  default: defaultGqlSdk,
}
export const GqClientOps = {"default":["HeaderModulesPage","HeaderDefault","Header3d","DefaultPageLink","DeepDiveLink","JobDeepDiveLink","ProjectDeepDiveLink","ModulesProject","ModulesDeepDive","ModulesJob","Modules","ContentText","ContentTestimonial","ContentTeamSlider","ContentImage","ContentImagesMultiple","ContentFigure","HomeProject","ProjectsSimple","ProjectsBig","TeaserTeam","ContentVideo","ContentCta","ContentLogowall","ContentBenefits","DeepDiveText","DeepDiveTeam","DeepDiveAsset","PageMeta","Testimonial","Item","Benefit","TeaserItem","JobLocation","FooterAddress","FigureElement","GridonicWay","TheGridonicWay","Asset","Link","IconLink","NavLink","NavContent","ProjectTeaser","ProjectTeaserWork","ProjectTeaserHome","ResponsiveImage","RichtextCta","TeamMember","GetAllProjects","GetControlPanel","MainNavigation","FooterNavigation","GetDeepDiveByTranslatedSlug","GetJobByTranslatedSlug","GetPageByTranslatedSlug","GetProjectByTranslatedSlug","GetSiteInfo","AddressSet","SiteInfo","GetTheGridonicWay","GetTranslatedSlugs","GetTranslationPanel","TranslationPanel"]}
export const GqlGetAllProjects = (...params) => useGql()('GetAllProjects', ...params)
export const GqlGetControlPanel = (...params) => useGql()('GetControlPanel', ...params)
export const GqlGetDeepDiveByTranslatedSlug = (...params) => useGql()('GetDeepDiveByTranslatedSlug', ...params)
export const GqlGetJobByTranslatedSlug = (...params) => useGql()('GetJobByTranslatedSlug', ...params)
export const GqlGetPageByTranslatedSlug = (...params) => useGql()('GetPageByTranslatedSlug', ...params)
export const GqlGetProjectByTranslatedSlug = (...params) => useGql()('GetProjectByTranslatedSlug', ...params)
export const GqlGetSiteInfo = (...params) => useGql()('GetSiteInfo', ...params)
export const GqlGetTheGridonicWay = (...params) => useGql()('GetTheGridonicWay', ...params)
export const GqlGetTranslatedSlugs = (...params) => useGql()('GetTranslatedSlugs', ...params)
export const GqlGetTranslationPanel = (...params) => useGql()('GetTranslationPanel', ...params)