<template>
  <a
    v-if="link.linkType === 'external'"
    class="link"
    :href="link.externalUrl ?? '#'"
    :target="link.openLinkInNewWindow ? '_blank' : '_self'"
  >
    <span class="text">{{ link.linkText }}</span>
    <slot />
  </a>
  <a
    v-else-if="link.linkType === 'email'"
    class="link"
    :href="`mailto:${link.emailUrl}`"
    :target="'_blank'"
  >
    <span class="text">{{ link.linkText }}</span>
    <slot />
  </a>
  <NuxtLink
    v-else-if="link.linkType === 'internal' && link.internalUrl"
    class="link"
    :to="path"
    :target="link.openLinkInNewWindow ? '_blank' : '_self'"
  >
    <span class="text">{{ link.linkText }}</span>
    <slot />
  </NuxtLink>
</template>

<script setup lang="ts">
import type { LinkFragment } from '#gql';
import { useInternalRoute } from '~/datocms/useInternalRoute';

const props = defineProps<{
  link: LinkFragment;
}>();

const { path } = useInternalRoute(props.link.internalUrl);
</script>

<style scoped lang="scss"></style>
