<template>
  <a
    v-if="data.link"
    class="icon-link"
    target="_blank"
    :href="data.link"
    :aria-label="String(data.title)"
  >
    <p class="tooltip text-sm">
      {{ data.title }}
    </p>
    <span class="icon" :style="{ maskImage: `url(${iconUrl})` }"></span>
    <span class="icon -double" :style="{ maskImage: `url(${iconUrl})` }"></span>
  </a>
</template>

<script setup lang="ts">
import type { IconLinkFragment } from '#gql';

const props = defineProps<{
  data: IconLinkFragment;
}>();

const iconUrl = computed(function () {
  if (props.data.icon && props.data.icon !== 'custom') {
    return `/icons/${props.data.icon}.svg`;
  }

  return props.data.svg?.url ?? '';
});
</script>

<style scoped lang="scss">
.icon-link {
  --size: 1.45rem;

  width: var(--size);
  height: var(--size);
  display: inline-block;
  position: relative;

  &:hover {
    box-shadow: 0 0 14px 4px rgba(218, 66, 221, 0.15);

    > .icon {
      &:not(.-double) {
        opacity: 0;
      }

      &.-double {
        opacity: 1;
      }
    }

    > .tooltip {
      opacity: 1;
      transform: translate(-50%, calc(-1 * var(--size) - 0.75rem));
    }
  }

  > .icon {
    width: 100%;
    height: 100%;
    display: inline-block;
    font-size: 0;
    mask-repeat: no-repeat;
    mask-size: 100% 100%;
    position: absolute;
    inset: 0;
    transition: opacity 350ms;

    &:not(.-double) {
      background-color: #ffffff;
      opacity: 1;
    }

    &.-double {
      background-image: linear-gradient(var(--gradient-red));
      opacity: 0;
    }
  }

  > .tooltip {
    transition:
      transform 350ms,
      opacity 250ms;
    position: absolute;
    background-color: #ffffff;
    color: var(--control-panel-background);
    user-select: none;
    pointer-events: none;
    z-index: 1000;
    opacity: 0;
    min-width: 3rem;
    max-width: 21rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0.75ch 1.25ch;
    border-radius: 0.6ch;
    margin-left: calc(0.5 * var(--size));
    transform: translate(-50%, calc(-1 * var(--size) - 1.25rem));
    bottom: 0;

    &::after {
      --height: 0.35rem;

      content: '';
      position: absolute;
      left: 50%;
      bottom: calc(var(--height) * -1.85);
      transform: translateX(-50%);
      border: var(--height) solid var(--color-font);
      border-color: var(--color-font) transparent transparent transparent;
    }
  }
}
</style>
