<template>
  <NuxtLink
    tabindex="0"
    aria-label="Home"
    :to="localePath('index')"
    class="gridonic-logo"
  >
    Gridonic
    <span class="logo" />
  </NuxtLink>
</template>

<script setup lang="ts">
const localePath = useLocalePath();
</script>

<style scoped lang="scss">
.gridonic-logo {
  --size: 2rem;
  --highlight: transparent;

  position: relative;
  font-size: 0;
  width: var(--size);
  height: var(--size);
  max-width: 2.5rem;
  max-height: 2.5rem;
  aspect-ratio: 1 / 1;
  flex-shrink: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 14px 4px var(--highlight);

  &.-light {
    --background: #ffffff;
    --color: #282828;
  }

  &.-dark {
    --background: #282828;
    --color: #ffffff;
  }

  &:hover,
  &:focus-visible {
    --highlight: rgba(218, 66, 221, 0.25);

    > .logo {
      transform: scale(0.8);
    }
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0.5px;
    border-radius: 50%;
    z-index: -1;
    background-image: linear-gradient(var(--gradient-red));
  }

  > .logo {
    transform: scale(1);
    transition:
      transform 350ms,
      background-color 350ms;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: var(--background);
    border-radius: 50%;
    margin: auto;
    display: flex;

    &::after {
      position: absolute;
      inset: 20%;
      content: '';
      mask-image: url('/icons/gridonic-logo.svg');
      mask-repeat: no-repeat;
      mask-size: 100% 100%;
      background-color: var(--color);
      transition: color 350ms;
    }
  }
}
</style>
